header {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 15px;
    z-index: 9;
    transition:  background-color 300ms ease;
}

header.scrolled {
    background-color: #848D8D;
}

.logo {
    width: 130px;
    display: inline-block;
}

.logo > img {
    width: 100%;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hamburger-menu {
    display: inline-block;
    position: relative;
    z-index: 2;
}

.hamburger-menu.open .line:nth-child(1) {
    transform: translate(0px, 7.5px) rotate(45deg);
    transform-origin: 50% 50%;
}

.hamburger-menu.open .line:nth-child(2) {
    transform: translate(0px, -3px) rotate(-45deg);
    transform-origin: 50% 50%;
}

ul {
    margin: 0;
    list-style: none;
    padding: 20px;
}

.menu {
    text-decoration: none;
    color: #FFF;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.line {
    width: 24px;
    height: 3px;
    background: #FFF;
    margin: 8px 0;
    transition: transform 300ms ease;
}

.list-menu {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #FF7A04;
    margin: 0;
    transform: translateX(100%);
    transition: transform 300ms ease;
}

.list-menu.open {
    transform: translateX(0);
}

.list-menu.open::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(../images/img-source/work.gif);
    background-size: cover;
    height: 250px;
}

.wrapper-logo {
    width: 130px;
    padding: 15px 15px 0;
}

.list-menu img {
   width: 100%;
}

@media screen and (min-width: 1024px) {
    .hamburger-menu {
        display: none;
    }

    .list-menu {
        position: relative;
        transform: unset;
        background-color: transparent;
    }

    .list-menu .wrapper-logo  {
        display: none;
    }

    ul {
        padding: 0;
    }

    .menu {
        display: inline-block;
        padding: 0 10px;
    }
}
