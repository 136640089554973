footer {
    background-color: #2F434B;
    padding: 15px;
    color: #FFF;
}

.img-logo {
    width: 130px;
}

.footer-address {
    font-size: 16px;

}

.information-footer {
    margin-top: 25px;
    margin-bottom: 25px;
}

.list-menu-footer ul {
    padding: 0;
}

.follow {
    margin-top: 25px;
}

.ic-follow {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #D9D9D9;
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
}

.copyright {
    font-size: 12px;
    margin-top: 25px;
}

footer .social-talk > a {
    margin: 0;
    margin-right: 10px;
}

@media screen and (min-width: 1024px) {
    footer {
        padding: 40px;
        display: flex;
        justify-content: space-between;
    }

    footer > div {
        width: 33%;
    }

    footer > div:nth-child(2) {
        padding: 0 80px;
    }

    footer > div:nth-child(3) {
        padding-left: 90px;
    }

    .follow {
        margin-top: 0;
    }

    .copyright {
        position: absolute;
        bottom: 40px;
    }

    footer .social-talk > a {
        margin-right: 20px;
    }
}