.home-page {
    min-height: calc(100vh);
    padding: 70px 0 0;
}

.banner {
    color: #FFF;
    text-align: center;
    padding-top: 45px;
}

.banner .small {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.banner p {
    font-size: 36px;
    font-weight: 600;
}

.btn-explore {
    color: #FF7A04;
    font-size: 20px;
    font-weight: 500;
    background-color: #FFF;
    border-radius: 8px;
    line-height: 50px;
    display: inline-block;
    padding: 0 30px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.banner-l {
    z-index: 2;
    position: relative;
}

.banner-r {
    margin-top: -80px;
}
.banner-desc {
    font-weight: 500;
}

.bar-text {
    color: #FFF;
    text-align: center;
    background-image: url(../images/bar-gray.jpg);
    background-size: cover;
    background-position: center;
    padding: 26px 0;
}

.bar-text h1 {
    font-size: 26px;
    font-weight: 600;
}

.intro {
    padding: 32px 0 0;
    text-align: center;
}

.text-intro {
    background-image: url(../images/bg-intro.png);
    background-size: cover;
    background-position: center;
    padding: 150px 20px;
    text-align: center;
}

.text-intro p {
    color: #666666;
    margin-bottom: 20px;
}

.text-intro .line {
    background-color: #666;
    width: 40px;
    height: 3px;
    margin-bottom: 20px;
    display: inline-block;
}

.link-contact {
    margin-top: 20px;
    display: inline-block;
    color: #666;
    font-weight: 500;
}

.link-contact span {
    margin: 0 15px;
}

.img-intro {
    margin-top: -140px;
}

.slide-works {
    text-align: center;
    padding-bottom: 40px;
}

.slide-works .swiper-slide {
    padding: 40px 20px 0;
}

.card-work {
    background-color: #FFF;
    border-radius: 45px;
}

.slide-works .swiper {
    margin-top: 45px;
    padding-top: 45px;
}

.text-work {
    color: #666;
    padding: 20px;
    padding: 66px 20px 40px;
}

.text-work h3 {
    margin-bottom: 10px;
}

.icon-work {
    width: 90px;
    height: 90px;
    background-color: #FF7A04;
    border-radius: 24px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -45px;
}

.link-work {
    margin-top: 20px;
    display: inline-block;
    color: #FF6947;
    font-size: 24px;
}

.slide-customer {
    text-align: center;
    padding-bottom: 40px;
}

.card-customer {
    background-color: #FFF;
    border-radius: 24px;
    padding: 20px 20px 30px;
}

.slide-customer .swiper {
    margin-top: 40px;
}

.slide-customer .swiper-slide {
    padding: 0 20px;
}

.icon-customer {
    background-color: #FF7A04;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: inline-block;
}

.icon-customer > svg {
    color: #FFF;
    font-size: 24px;
    padding-top: 12px;
}

.text-customer {
    color: #666;
    margin-top: 15px;
    margin-bottom: 30px;
}

.star > svg {
    color: #FF7A04;
    margin: 0 3px;
}

.name-customer {
    font-size: 18px;
    font-weight: 500;
    color: #FF7A04;
}

.company-customer {
    font-weight: 500;
    color: #FF7A04;
}

@media screen and (min-width: 1024px) {
    .banner {
        display: flex;
        padding: 0;
        justify-content: space-between;
        height: 700px;
        max-width: 1000px;
        margin: 0 auto;
    }
    
    .banner-l {
        padding: 100px 0 0 140px;
    }

    .banner-desc {
        position: absolute;
        left: 40px;
        bottom: 70px;
        text-align: left;
        width: 300px;
    }

    .banner-r {
        position: absolute;
        right: 0;
    }
    
    .banner-r > img {
        max-height: 700px;
        object-fit: contain;
    }

    .bar-text {
        background-image: url(../images/bar-gray-dt.jpg);
        padding: 50px 0;
        background-size: cover;
    }

    .bar-text h1 {
        font-size: 40px;
    }

    .intro {
        padding-bottom: 40px;
        overflow: hidden;
    }

    .wrapper-intro {
        display: flex;
        max-width: 1000px;
        margin: 0 auto;
        align-items: center;
    }

    .text-intro {
        background-image: url(../images/bg-intro-dt.png);
        padding: 170px 100px;
        width: 70%;
    }

    .img-intro {
        /* position: absolute; */
        /* right: 40px; */
        width: 30%;
        margin-top: 0;
    }

    .slide-works .swiper-slide {
        padding: 40px 0;
        width: 420px !important;
    }

    .slide-works .swiper-container {
        width: 100%; 
        padding: 0 15px;
    }

    .slide-customer .swiper-slide {
        width: 420px !important;
        padding: 0;
    }
}

