.services-banner {
    background-image: url(../images/bg-banner.jpg);
    background-size: cover;
    background-position: center;
    color: #FFF;
    padding: 100px 0 40px;
}

.services-page h1 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
}

.services-banner-l {
    padding-left: 55px;
    padding-right: 20px;
}

.services-banner-l > p {
    margin-bottom: 20px;
    letter-spacing: 0.4px;
}

.wrapper-list {
    padding: 36px 20px;
}

.list-services {
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.1);
    text-align: center;
    color: #000;
    line-height: 50px;
    font-weight: 600;
    margin-bottom: 20px;
}

.list-services.contact {
    background-color: #FF7A04;
    color: #FFF;
    display: inline-block;
    width: 100%;
}

.services-all {
    background-color: #F6F6FF;
}

.card-services {
    height: 250px;
    border-radius: 20px;
    background-color: #FFF;
}

.wrapper-services-slide {
    overflow: hidden;
    padding-bottom: 40px;
}

.wrapper-services-slide:nth-child(odd) {
    background-color: #F8FDFF;
}

.wrapper-services-slide:nth-child(even) {
    background-color: #F6F6FF;
}

.wrapper-services-slide:nth-child(even) h3 {
    text-align: right;
}

.wrapper-services-slide:nth-child(even) .heading-slide::before {
    right: -23px;
    left: unset;
}

.heading-slide {
    color: #F6F6FF;
    position: relative;
}

.heading-slide > h3 {
    position: relative;
    z-index: 2;
    padding: 35px 20px;
}

.heading-slide::before {
    content: "";
    position: absolute;
    width: 190px;
    height: 190px;
    border-radius: 20px;
    background-color: #FF7A04;
    z-index: 1;
    transform: rotate(45deg);
    top: -30px;
    left: -23px;
}

.services-slide .swiper-slide {
    padding: 0 20px;
}

.card-services {
    padding: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 7px
}

.card-services > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

@media screen and (min-width: 1024px) {
    .services-banner {
        background: unset;
        color: #000;
        position: relative;
        overflow: hidden;
    }

    .services-banner > div {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
    } 

    .services-banner:after {
        content: "";
        position: absolute;
        background-image: url("../images/bg-banner-dt.png");
        background-size: cover;
        top: 0;
        right: 0;
        width: 850px;
        height: 700px;
    }

    .services-page h1 {
        color: #FF7A04;
    }

    .services-banner-r {
        max-width: 500px;
    }

    .wrapper-list {
        max-width: 1000px;
        margin: 0 auto;
        padding-bottom: 0;
    }

    .list-services,
    .list-services.contact {
        display: inline-block;
        width: calc(33% - 20px);
        margin: 0 10px 20px;
    }

    .img-services {
        max-width: 300px;
        margin: 0 auto;
    }

    .services-slide .swiper-slide {
        width: 500px !important;
    }
}

